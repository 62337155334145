import { UserRole } from './../../models/user-role';
import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';

import { AdminRolesService } from '../../services/admin.roles.service';
import { AdminCreateUserService } from '../../services/admin.create-user.service';
import { AdminUserListService } from '../../services/admin.user-list.service';

import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, FormArray } from '@angular/forms';
import { Observable, Observer, Subscription } from 'rxjs';
import { CreateUserRequest } from '../../models/create-user-request';
import { OnUserCreatedEvent } from '../../models/on-user-created-event';
import { SlideDownAnim } from 'src/app/animations/slideDownAnim';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'admin-create-user',
  templateUrl: './admin-create-user.component.html',
  styleUrls: ['./admin-create-user.component.scss'],
  animations: [SlideDownAnim]
})
export class AdminCreateUserComponent implements OnDestroy {
  @Output() onDone: EventEmitter<any> = new EventEmitter();

  get email() { return this.form.get('email'); }
  get name() { return this.form.get('name'); }
  get surname() { return this.form.get('surname'); }
  get roles() { return this.form.get('roles') as FormArray; };

  form: FormGroup;
  userCreatingData: OnUserCreatedEvent;
  userRoles$: Observable<UserRole[]>;
  userRoles: UserRole[];
  private userCreatedSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private adminRolesService: AdminRolesService,
    private adminCreateUserService: AdminCreateUserService,
    private adminUserListService: AdminUserListService,
    private toast: ToastService) {

    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email], [this.emailAsyncValidator]),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      roles: new FormArray([])
    });

    this.userRoles$ = adminRolesService.roles;

    this.adminRolesService.roles.subscribe((newRoles: UserRole[]) => {
      this.userRoles = newRoles;
      newRoles.map(r => {
        this.roles.push(this.fb.control(false));
      });
    });

    this.userCreatedSub = adminCreateUserService.userCreatingData.subscribe((e: OnUserCreatedEvent) => {
      this.userCreatingData = e;

      if (e && e.userCreated) {
        this.toast.success(
          'User has been sucesfully created.'
        );
        this.adminUserListService.getUsers();
        this.onDone.emit();
        this.reset();
      }
    });
  }

  cancel(e: MouseEvent) {
    e.preventDefault();
    this.onDone.emit();
    this.reset();
  }

  create(values: CreateUserRequest) {
    const selectedRoleIds = this.form.value.roles
      .map((v: any, i: string | number) => v ? this.userRoles[i].id : null)
      .filter((v: any) => v !== null);

    var createUserRequest: CreateUserRequest = values;
    createUserRequest.roles = selectedRoleIds;

    this.adminCreateUserService.createUser(createUserRequest);
  }

  ngOnDestroy(): void {
    this.userCreatedSub.unsubscribe();
  }

  emailAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      this.adminCreateUserService.checkIfEmailTaken(control.value).subscribe((isTaken: boolean) => {
        if (isTaken) {
          observer.next({ error: true, duplicated: true });
        }
        else {
          observer.next(null);
        }
        observer.complete();
      });
    });

  private reset() {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }
}
