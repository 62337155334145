import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from 'src/app/modules/login/models/user-info';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-overview',
  templateUrl: './settings-overview.component.html',
  styleUrls: ['./settings-overview.component.scss']
})
export class SettingsOverviewComponent implements OnInit {

  public isEditing: boolean = false;
  public editedClientId: number;
  public editedScannedUrl: string;

  public userInfo$: Observable<UserInfo>;
  public clientSetting$: Observable<ClientSetting>;

  //public gettingClients$: Observable<boolean>;
  private clientsSubject = new BehaviorSubject<Client[]>(undefined);
  public clients$ = this.clientsSubject.asObservable();

  public clientForm: FormGroup;
  public scannedUrlForm: FormGroup;

  public constructor(private readonly usersService: UsersService, private readonly fb: FormBuilder, private readonly httpClient: HttpClient) { }

  public initEdit(id: number, scannedUrl: string): void {
    this.isEditing = true;
    this.editedClientId = id;
    this.scannedUrlForm.controls['scannedUrl'].setValue(scannedUrl);
    this.editedScannedUrl = scannedUrl;
  }

  public editClient(): void {
    this.httpClient.put(`${environment.apiUrl}/users/${this.editedClientId}/scannedUrl`, { scannedUrl: this.scannedUrlForm.value.scannedUrl })
      .subscribe(() => {
        this.refreshClients();
        this.stopEditing();
      });
  }

  public stopEditing(): void {
    this.isEditing = false;
    this.editedClientId = undefined;
    this.editedScannedUrl = undefined;
  }

  public ngOnInit(): void {
    this.userInfo$ = this.usersService.userInfo;

    this.clientForm = this.fb.group({
      name: [{ disabled: true, value: '' }],
      surname: [{ disabled: true, value: '' }],
      email: [{ disabled: true, value: '' }],
      phone: [{ disabled: true, value: '' }],
      scannedUrl: [{ disabled: true, value: '' }],
    });

    this.scannedUrlForm = this.fb.group({
      scannedUrl: new FormControl('', [Validators.required])
    });

    this.userInfo$.subscribe(userInfo => {
      if (!userInfo) {
        return;
      }

      if (userInfo.isClient) {
        this.clientSetting$ = this.httpClient.get<ClientSetting>(`${environment.apiUrl}/settings`);
      }

      if (userInfo.isAdmin) {
        this.refreshClients();
      }
    })
  }

  private refreshClients(): void {
    this.httpClient.get<Client[]>(`${environment.apiUrl}/users/clients`).subscribe(clients => this.clientsSubject.next(clients));
  }

  public scheduleReport(userId: number): void {
    this.httpClient.post<void>(`${environment.apiUrl}/users/${userId}/reports`, {})
      .subscribe(() => (console.log("report scheduled")));
  }
}

export interface ClientSetting {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  scannedUrl: string;
}

export interface Client {
  id: number;
  email: string;
  scannedUrl: string;
}
