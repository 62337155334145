import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf, faWindowClose, faCheck, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {ProgressBarModule} from "angular-progress-bar"
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';

import { LoaderTableComponent } from './loader-table/loader-table.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ReportStatusBadgeComponent } from './report-status-badge/report-status-badge.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavBarEmptyComponent } from './nav-bar-empty/nav-bar-empty.component';
import { SpinnerSmallComponent } from './spinner-small/spinner-small.component';
import { ReportSecurityScoreComponent } from './report-security-score/report-security-score.component';
import { ReportSecurityScoreIconComponent } from './report-security-score-icon/report-security-score-icon.component';
import { ReportPassedComponent } from './report-passed/report-passed.component';
import { ReportUserAssignComponent } from './report-user-assign/report-user-assign.component';
import { ReportDetailsComponent } from './report-details/report-details.component';

/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { ReportStatisticChartComponent } from './report-statistic-chart/report-statistic-chart.component';
registerLocaleData(en);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    ChartsModule,
  ],
  exports: [
    ProgressBarModule,
    NgZorroAntdModule,
    FontAwesomeModule,
    
    LoaderTableComponent,
    SpinnerComponent,
    SpinnerSmallComponent,
    ReportStatusBadgeComponent,
    NavBarComponent,
    NavBarEmptyComponent,
    ReportSecurityScoreComponent,
    ReportSecurityScoreIconComponent,
    ReportPassedComponent,
    ReportUserAssignComponent,
    ReportDetailsComponent,
    ReportStatisticChartComponent
  ],
  declarations: [
    LoaderTableComponent,
    SpinnerComponent,
    SpinnerSmallComponent,
    ReportStatusBadgeComponent,
    NavBarComponent,
    NavBarEmptyComponent,
    ReportSecurityScoreComponent,
    ReportSecurityScoreIconComponent,
    ReportPassedComponent,
    ReportUserAssignComponent,
    ReportDetailsComponent,
    ReportStatisticChartComponent,
  ],
  providers   : [
    { provide: NZ_I18N, useValue: en_US }
  ]
})
export class ComponentsModule {
  constructor() {
    library.add(faFilePdf, faWindowClose, faCheck, faTimes, faPlus);
  }
}
