import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { SetPasswordComponent } from './component/set-password/set-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { showNavbar: false } },
  { path: 'set-password', component: SetPasswordComponent, data: { showNavbar: false } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
