import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-passed',
  styleUrls: ['./report-passed.component.scss'],
  templateUrl: './report-passed.component.html'
})
export class ReportPassedComponent {
  @Input()
  passed: boolean;

  passedClassFore() {
    if (this.passed) return 'passed'
    else return 'not-passed';
  }
}
