import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-security-score',
  styleUrls: ['./report-security-score.component.scss'],
  templateUrl: './report-security-score.component.html'
})
export class ReportSecurityScoreComponent {
  @Input()
  score: number;

  @Input()
  showIcon: boolean = true;

  scoreString() {
    switch (this.score) {
      case 0: return 'Low';
      case 1: return 'Medium';
      case 2: return 'High';
      default: return 'Info';
    }
  }

  scoreClassBg() {
    switch (this.score) {
      case 0: return 'severity-low';
      case 1: return 'severity-medium';
      case 2: return 'severity-high';
      default: return 'severity-info';
    }
  }

  scoreClassFore() {
    switch (this.score) {
      case 0: return 'severity-low-color';
      case 1: return 'severity-medium-color';
      case 2: return 'severity-high-color';
      default: return 'severity-info-color';
    }
  }
}
