import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ToastService } from 'src/app/services/toast.service';
import { LoginRequest } from './../modules/login/models/login-request';
import { environment } from 'src/environments/environment';
import { TokenData } from '../modules/login/models/token-data';
import { UserInfo } from '../modules/login/models/user-info';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  currentUserId: number;
  currentUserName: string;
  private apiUrl = environment.apiUrl;

  private userInfoSubject = new BehaviorSubject<UserInfo>(null);
  readonly userInfo = this.userInfoSubject.asObservable();

  private logginInSubject = new BehaviorSubject<boolean>(false);
  readonly loggingIn = this.logginInSubject.asObservable();

  private settingPasswordSubject = new BehaviorSubject<boolean>(false);
  readonly settingPassword = this.settingPasswordSubject.asObservable();

  private resettingPasswordSubject = new Subject<boolean>();
  readonly resettingPassword = this.resettingPasswordSubject.asObservable();

  readonly dataStore: { userInfo: UserInfo } = { userInfo: null };

  public constructor(private http: HttpClient, private router: Router, private toastr: ToastService) {
    const email = localStorage.getItem("userEmail");
    this.dataStore.userInfo = {
      email,
      isAdmin: false,
      isConsultant: false,
      isPlanner: false,
      isClient: false,
    };
    this.userInfoSubject.next(Object.assign({}, this.dataStore).userInfo);
  }

  public login(email: string, password: string): Observable<TokenData> {
    const loginRequest: LoginRequest = { email, password };

    this.logginInSubject.next(true);
    this.userInfoSubject.next(null);
    return this.http.post(`${this.apiUrl}/account/login`, loginRequest).pipe(map((userInfo: TokenData) => {
      localStorage.setItem('userEmail', email);
      localStorage.setItem('token', userInfo.token);
      localStorage.setItem('refreshToken', userInfo.refreshToken);

      const decoded = jwt_decode(userInfo.token);
      let roles: string[] = decoded.role;

      this.dataStore.userInfo = {
        email,
        isAdmin: roles.indexOf("Admin") > -1,
        isConsultant: roles.indexOf("Consultant") > -1,
        isPlanner: roles.indexOf("Planner") > -1,
        isClient: roles.indexOf("Client") > -1
      };
      this.userInfoSubject.next(Object.assign({}, this.dataStore).userInfo);
      this.logginInSubject.next(false);

      return userInfo;
    }), catchError((error) => {
      this.logginInSubject.next(false);
      throw error;
    }));
  }

  public refreshToken(): Observable<TokenData> {
    var refreshTokenRequest = {
      'token': localStorage.getItem("token"),
      'refreshToken': localStorage.getItem("refreshToken")
    };

    return this.http.post<TokenData>(`${this.apiUrl}/account/refresh`, refreshTokenRequest)
      .pipe(
        map(user => {

          if (user && user.token) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('refreshToken', user.refreshToken);
          }

          return <TokenData>user;
        }));
  }

  public getAuthToken(): string {
    return localStorage.getItem("token");
  }

  public setPassword(password: string) {
    this.settingPasswordSubject.next(true);

    this.http.put(`${this.apiUrl}/account/password`, { password }).subscribe(data => {
      this.settingPasswordSubject.next(false);
      this.toastr.success('Your password has been succesfully updated.');
      this.router.navigate(['reports']);
    });
  }

  public resetPassword(username: string) {
    this.resettingPasswordSubject.next(true);

    this.http.post(`${this.apiUrl}/account/password/reset`, { username }).subscribe(data => {
      this.resettingPasswordSubject.next(false);
      this.toastr.success('Password reset instructions has been succesfully sent.');
    });
  }

  public logout(): void {
    this.userInfoSubject.next(null);
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.router.navigate(['/login']);
  }
}
