import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsOverviewComponent } from './component/settings-overview/settings-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [SettingsOverviewComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,

  ]
})
export class SettingsModule { }
