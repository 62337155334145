import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserDetails } from '../models/user-details';
import { OnUserUpdatedEvent } from '../models/on-user-updated-event';
import { UpdateUserRequest } from '../models/update-user-request';

@Injectable({
  providedIn: 'root'
})
export class AdminEditUserService {
  constructor(private httpClient: HttpClient) { }
  private apiUrl = environment.apiUrl;

  private userSubject = new Subject<UserDetails>();
  private userUpdatingSubject = new Subject<OnUserUpdatedEvent>();

  readonly user: Observable<UserDetails> = this.userSubject.asObservable();
  readonly userUpdatingData: Observable<OnUserUpdatedEvent> = this.userUpdatingSubject.asObservable();

  public getUserDetails(id: number) {
    this.userSubject.next(null);

    this.httpClient.get<UserDetails>(`${this.apiUrl}/users/${id}`).subscribe(data => {
      this.userSubject.next(data);
    });
  }

  public updateUser(user: UpdateUserRequest) {
    this.userUpdatingSubject.next({
      inProgress: true,
      userUpdated: false,
      errors: []
    });

    this.httpClient.put(`${this.apiUrl}/users/`, user).subscribe(
      () => {
        //all ok
        this.userUpdatingSubject.next({
          inProgress: false,
          userUpdated: true,
          errors: []
        });
      },
      (err: any) => {
        //error
        this.userUpdatingSubject.next({
          inProgress: false,
          userUpdated: false,
          errors: [err.error]
        });
      });
  }

  public checkIfEmailTaken(email: string, excludedUserId: number) {
    return this.httpClient.get<boolean>(`${this.apiUrl}/users/emailTaken?email=${email}&excludedUserId=${excludedUserId}`);
  }
}
