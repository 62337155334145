import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { FadeOutAnim } from 'src/app/animations/fadeOutAnim';
import { NzModalService } from 'ng-zorro-antd';
import { TokenData } from '../../models/token-data';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [FadeInAnim, FadeOutAnim]
})
export class LoginComponent implements OnInit {
  private validationSubject = new BehaviorSubject(false);
  public $validationErrorVisible: Observable<boolean> = this.validationSubject.asObservable();

  public forgotPasswordVisible: boolean;
  public $loggingIn: Observable<boolean>;
  public $resettingPassword: Observable<boolean>;
  public validateForm: FormGroup;
  public resetPasswordForm: FormGroup;

  public get surname() { return this.resetPasswordForm.get('userName'); }

  public constructor(private readonly fb: FormBuilder,
    private readonly usersService: UsersService,
    private readonly route: ActivatedRoute,
    private readonly modalService: NzModalService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {
    this.$loggingIn = this.usersService.loggingIn;
    this.$resettingPassword = this.usersService.resettingPassword;

    this.$resettingPassword.subscribe(data => {
      if (data === false) {
        this.forgotPasswordVisible = false;
      }
    })

    this.validateForm = this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.resetPasswordForm = this.fb.group({
      userName: ['', [Validators.required]],
    });

    this.validateForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => this.validationSubject.next(false));

    this.modalService.closeAll();
  }

  public showForgotPassword(): void {
    this.surname.setValue(this.validateForm.value.userName);
    this.forgotPasswordVisible = true;
  }

  public showLogin(): void {
    this.forgotPasswordVisible = false;
  }

  public login(): void {
    const formFields = this.validateForm.value;
    const redirectUrl = this.route.snapshot.queryParams.returnUrl || '/reports';
    this.usersService.login(formFields.userName, formFields.password)
      .subscribe((userInfo: TokenData) => {
        if (userInfo.passwordUpdateRequired) {
          this.router.navigate(['set-password']);
          return;
        }

        this.router.navigateByUrl(redirectUrl);
      }, () => {
        // Display validation banner
        this.validationSubject.next(true);
      });
  }

  public resetPassword(): void {
    this.usersService.resetPassword(this.resetPasswordForm.value.userName);
  }
}
