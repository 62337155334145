import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorsRoutingModule } from './errors-routing.module';
import { Error404Component } from './404/404.component';
import { Error403Component } from './403/403.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [

    CommonModule,
    ComponentsModule,
    ErrorsRoutingModule,
  ],
  declarations: [Error404Component, Error403Component]
})
export class ErrorsModule { }
