import { AdminRootComponent } from './component/admin-root/admin-root.component';
import { AdminUSerListComponent } from './component/user-list/admin-user-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin.routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { AdminCreateUserComponent } from './component/create-user/admin-create-user.component';
import { AdminEditUserComponent } from './component/edit-user/admin-edit-user.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AdminRootComponent,
    AdminUSerListComponent,
    AdminCreateUserComponent,
    AdminEditUserComponent,
  ]
})
export class AdminModule { }
