import { Injectable } from '@angular/core';
import { ReportListItem } from '../modules/reports/models/report-list-item';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { InitScanRequest } from '../modules/reports/models/init-scan-request';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ReportDetails } from '../modules/reports/models/report-details';
import { UserListItem } from '../modules/reports/models/user-list-item';
import { ReportStatistic } from '../modules/reports/models/report-statistic';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private apiUrl = environment.apiUrl;
  private numberOfReports = environment.reports.statistics.reportCount;
  private syncInterval = 15000;

  currentReportSubject = new BehaviorSubject<ReportDetails>(null);
  reportsSubject = new BehaviorSubject<ReportListItem[]>([]);
  reportsLiteSubject = new BehaviorSubject<ReportListItem[]>([]);
  gettingReportsSubject = new BehaviorSubject<boolean>(false);
  gettingReportsLiteSubject = new BehaviorSubject<boolean>(false);

  private gettingReportUsersToAssugnSubject = new BehaviorSubject<boolean>(false);
  private reportUsersToAssignSubject = new BehaviorSubject<UserListItem[]>([]);
  private assigningUserToReportSubject = new BehaviorSubject<boolean>(false);

  readonly gettingReportUsersToAssign: Observable<boolean> = this.gettingReportUsersToAssugnSubject.asObservable();
  readonly reportUsersToAssign: Observable<UserListItem[]> = this.reportUsersToAssignSubject.asObservable();
  readonly assigningUserToReport: Observable<boolean> = this.assigningUserToReportSubject.asObservable();

  private dataStore:
    {
      currentReport: ReportDetails,
      reports: ReportListItem[],
      reportsLite: ReportListItem[],
      gettingReports: boolean,
      gettingReportsLite: boolean,
      syncing: any
    } = {
      currentReport: null,
      reports: [],
      reportsLite: [],
      gettingReports: false,
      gettingReportsLite: false,
      syncing: {}
    };

  public constructor(private http: HttpClient) { }

  pushNewToList(item: ReportListItem) {
    this.dataStore.reports.unshift(item);
    this.dataStore.syncing[item.id] = true;

    const newReports = Object.assign({}, this.dataStore).reports;
    this.reportsSubject.next([...newReports]);
  }

  loadRegisteredReports() {
    this.gettingReportsSubject.next(true);
    this.reportsSubject.next(null);

    this.http.get<ReportListItem[]>(`${this.apiUrl}/reports`).subscribe(data => {
      data.forEach((r, i) => {
        if (r.status === 0) {
          this.dataStore.syncing[r.id] = true;
        }
      });

      this.dataStore.reports = data;
      const newReports = Object.assign({}, this.dataStore).reports;

      this.reportsSubject.next([...newReports]);
      this.gettingReportsSubject.next(false);
    });
  }

  loadUnregisteredReports() {
    this.gettingReportsLiteSubject.next(true);
    this.reportsLiteSubject.next(null);

    this.http.get<ReportListItem[]>(`${this.apiUrl}/reportslite`).subscribe(data => {
      data.forEach((r, i) => {
        if (r.status === 0) {
          this.dataStore.syncing[r.id] = true;
        }
      });

      this.dataStore.reportsLite = data;
      const newReports = Object.assign({}, this.dataStore).reportsLite;

      this.reportsLiteSubject.next([...newReports]);
      this.gettingReportsLiteSubject.next(false);
    });
  }

  public refreshStatus(reportId: string): void {

    this.http.get<ReportListItem>(`${this.apiUrl}/reports/${reportId}/status`)
      .subscribe((data: ReportListItem) => {
        let updateNeeded = false;

        this.dataStore.reports.forEach((r, i) => {
          if (r.id.toString() === reportId) {
            if (this.dataStore.reports[i].status !== data.status) {
              updateNeeded = true;
            }

            if (data.status !== 0) {
              delete this.dataStore.syncing[r.id];
            }

            this.dataStore.reports[i] = data;
          }
        });

        if (updateNeeded) {
          const newReports = Object.assign({}, this.dataStore).reports;
          this.reportsSubject.next([...newReports]);
        }
      });
  }

  getDetails(reportId: number) {
    this.dataStore.currentReport = null;
    this.currentReportSubject.next(null);

    this.http.get<ReportDetails>(`${this.apiUrl}/reports/${reportId}`).subscribe(data => {
      this.dataStore.currentReport = data;
      this.currentReportSubject.next(Object.assign({}, this.dataStore).currentReport);
    });
  }

  getDetailsLite(reportId: number, pin: string) {
    this.dataStore.currentReport = null;
    this.currentReportSubject.next(null);

    let requestObservable = this.http.get<ReportDetails>(`${this.apiUrl}/reportslite/${reportId}?pin=${pin}`);

    requestObservable.subscribe(data => {
      this.dataStore.currentReport = data;
      this.currentReportSubject.next(Object.assign({}, this.dataStore).currentReport);
    });

    return requestObservable;
  }

  public initReport(request: InitScanRequest): Observable<ReportListItem> {
    return this.http.post<ReportListItem>(`${this.apiUrl}/reports`, request);
  }

  public downloadPdfFile(id: number): Observable<HttpEvent<Blob>> {
    return this.http.get(
      `${this.apiUrl}/reports/${id}/download/pdf`,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'Content-Type': 'application/pdf' })
      }
    );
  }

  public downloadPdfLiteFile(id: number): Observable<HttpEvent<Blob>> {
    return this.http.get(
      `${this.apiUrl}/reportslite/${id}/download/pdf`,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'Content-Type': 'application/pdf' })
      }
    );
  }

  validateInitReportToken(token: string) {
    return this.http.get<boolean>(`${this.apiUrl}/reportslite/${token}/validate`);
  }

  getUsersAvailableToAssign() {
    this.gettingReportUsersToAssugnSubject.next(true);

    this.http.get<UserListItem[]>(`${this.apiUrl}/userreports`).subscribe(data => {
      this.gettingReportUsersToAssugnSubject.next(false);
      this.reportUsersToAssignSubject.next(data);
    });
  }

  assignUsersToReport(request: any) {
    this.assigningUserToReportSubject.next(true);

    this.http.post(`${this.apiUrl}/userreports`, request)
      .subscribe(
        () => {
          let reportInStore = this.dataStore.reports.find((r: ReportListItem) => r.id == request.reportId);
          reportInStore.assignedUsers = request.userIds;

          this.assigningUserToReportSubject.next(false);
        });
  }

  public getReportStatisticsByUrl(url: string): Observable<ReportStatistic[]> {
    const queryString = `url=${url}&numberOfReports=${this.numberOfReports}`;

    return this.http.get<ReportStatistic[]>(`${this.apiUrl}/reports/statistics?${queryString}`);
  }

  public getReportStatisticsById(reportId: number): Observable<ReportStatistic[]> {
    const queryString = `numberOfReports=${this.numberOfReports}`;

    return this.http.get<ReportStatistic[]>(`${this.apiUrl}/reports/${reportId}/statistics?${queryString}`);
  }

  public getReportUrls(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/reports/urls`);
  }

  public deleteReport(reportId: number): Observable<void> {
    this.gettingReportsSubject.next(true);

    return this.http.delete<void>(`${this.apiUrl}/reports/${reportId}`).pipe(tap(() => {
      const reports = this.dataStore.reports.filter(r => r.id !== reportId);
      this.dataStore.reports = reports;

      this.reportsSubject.next([...reports]);
      this.gettingReportsSubject.next(false);
    }));
  }

}
