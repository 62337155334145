import { UserListItem } from './../../modules/reports/models/user-list-item';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-report-user-assign',
  styleUrls: ['./report-user-assign.component.scss'],
  templateUrl: './report-user-assign.component.html'
})
export class ReportUserAssignComponent implements OnInit {
  @Input() public reportId: number;
  @Input() public selectedUsers: number[];
  @Output() public userListChangedEvent = new EventEmitter();

  public users$: Observable<UserListItem[]>;
  public usersLoading$: Observable<boolean>;

  public constructor(private reportsService: ReportsService) {
    this.users$ = reportsService.reportUsersToAssign;
    this.usersLoading$ = this.reportsService.gettingReportUsersToAssign;
  }

  public ngOnInit(): void {
    this.reportsService.getUsersAvailableToAssign();
  }

  public isNotSelected(id: number): boolean {
    let selected = this.selectedUsers.find((u: number) => u === id);
    return !selected;
  }

  public onChange(): void {
    this.userListChangedEvent.emit({
      reportId: this.reportId,
      userIds: this.selectedUsers
    })
  }
}
