import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private options = {
    nzDuration: 3000
  };

  constructor(private message: NzMessageService) {}

  success(message: string) {
    this.message.success(message, this.options);
  }

  error(message: string) {
    this.message.error(message, this.options);
  }

  info(message: string) {
    this.message.info(message, this.options);
  }
}
