import { Component, OnDestroy, OnInit } from '@angular/core';

import { SlideRightAnim } from 'src/app/animations/slideRightAnim';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportStatistic } from '../../models/report-statistic';
import { UsersService } from 'src/app/services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reports-landing',
  templateUrl: './reports-landing.component.html',
  styleUrls: ['./reports-landing.component.scss'],
  animations: [SlideRightAnim]
})
export class ReportsLandingComponent implements OnInit, OnDestroy {
  public userInfoSubscription: Subscription;
  public isNotClient: boolean;

  public reportStatisticsData: ReportStatistic[];
  public urls: string[];
  public filteredUrls: string[] = [];
  public searchInputValue: string = '';
  public isLoadingChartData: boolean = false;

  public constructor(private readonly reportsService: ReportsService, private readonly usersService: UsersService) {
  }

  public ngOnInit(): void {
    this.reportsService.getReportUrls().subscribe((urls) => {
      this.filteredUrls = this.urls = urls;
    });

    this.userInfoSubscription = this.usersService.userInfo.subscribe(userInfo => {
      this.isNotClient = userInfo && (userInfo.isAdmin || userInfo.isConsultant || userInfo.isPlanner);
    });
  }

  public ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
  }

  public refreshChartData(url: string): void {
    this.reportsService.getReportStatisticsByUrl(url).subscribe((data) => {
      this.reportStatisticsData = data;
    });
  }

  public onSearchInputChange(value: string): void {
    this.filteredUrls = this.urls.filter(url => url.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public getStatisticsForUrl(): void {
    this.isLoadingChartData = true;
    this.reportsService.getReportStatisticsByUrl(this.searchInputValue).subscribe((data) => {
      this.reportStatisticsData = data;
      this.isLoadingChartData = false;
    }, () => {
      this.isLoadingChartData = false;
    })
  }
}
