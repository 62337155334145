import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportsModule } from './modules/reports/reports.module';
import { AdminModule } from './modules/admin/admin.module';
import { LoginModule } from './modules/login/login.module';
import { ErrorsModule } from './modules/errors/errors.module';
import { SetHeadersInterceptor } from './interceptors/set-headers-interceptor';
import { ResourceNotFoundInterceptor } from './interceptors/resource-not-found-interceptor';
import { UnauthorisedInterceptor } from './interceptors/unauthorised-interceptor';
import { SharedServicesModule } from './services/shared.services.module';
import { ComponentsModule } from './components/components.module';
import { SettingsModule } from './modules/settings/settings.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedServicesModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    ReportsModule,
    LoginModule,
    ErrorsModule,
    AdminModule,
    AppRoutingModule,
    SettingsModule,
    ComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SetHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResourceNotFoundInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorisedInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
