import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { InitReportAccessTokenResponse } from '../models/init-report-access-token-response';

@Injectable({
  providedIn: 'root'
})
export class AdminReportsService {
  public constructor(private readonly httpClient: HttpClient) { }

  private apiUrl = environment.apiUrl;

  private initReportTokenSubject = new BehaviorSubject<string>('');
  readonly token = this.initReportTokenSubject.asObservable();

  public getInitReportAccessToken(): void {
    this.httpClient.get<InitReportAccessTokenResponse>(`${this.apiUrl}/reports/token`).subscribe(data => {
      this.initReportTokenSubject.next(data.token);
    }, () => {});
  }
}
