import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { FadeOutAnim } from 'src/app/animations/fadeOutAnim';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  animations: [FadeInAnim, FadeOutAnim]
})
export class SetPasswordComponent {
  $settingPassword: Observable<boolean>;
  validateForm: FormGroup;

  hasLowercase: boolean = false;
  hasUppercase: boolean = false;
  hasNumber: boolean = false;
  hasSpecial: boolean = false;
  hasLength: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private usersService: UsersService, private route: ActivatedRoute) {
    this.$settingPassword = usersService.settingPassword;

    this.validateForm = this.fb.group({
      password: ['', [Validators.required]],
      confirm: new FormControl('', [this.confirmValidator, this.passwordPolicyValidator])
    });
  }

  updateValidation(event: KeyboardEvent) {
    let p = this.validateForm.value.password;
    this.hasLowercase = (/[a-z]/.test(p));;
    this.hasUppercase = (/[A-Z]/.test(p));;
    this.hasNumber = (/[0-9]/.test(p));;
    this.hasSpecial = (/[\*\|\,\\\"\:\<\>\[\]\{\}\`\';\(\)\@\&\$\#\%\!]/.test(p));;
    this.hasLength = p.length >= 8;
  }

  save() {
    const formFields = this.validateForm.value;
    this.usersService.setPassword(formFields.password);
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  passwordPolicyValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!this.hasLowercase
      || !this.hasUppercase
      || !this.hasNumber
      || !this.hasSpecial
      || !this.hasLength) {
      return { error: true, policy: true };
    }

    return {};
  };
}
