import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ReportListItem } from '../models/report-list-item';

@Injectable()
export class ReportImportExportService {
  private apiUrl = environment.apiUrl;

  public constructor(private readonly http: HttpClient) { }

  public export(from: Date, to: Date): Observable<HttpEvent<Blob>> {
    return this.http.get(
      `${this.apiUrl}/reports/export?from=${from.toJSON()}&to=${to.toJSON()}`,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' })
      }
    );
  }

  public exportJSON(from: Date, to: Date): Observable<HttpEvent<Blob>> {
    return this.http.get(
      `${this.apiUrl}/reports/exportJson?from=${from.toJSON()}&to=${to.toJSON()}`,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' })
      }
    );
  }

  public import(formData: FormData): Observable<ReportListItem[]> {
    return this.http.post<ReportListItem[]>(`${this.apiUrl}/reports/import`, formData);
  }
}
