import { ToastService } from './../../../../services/toast.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-report-details-anonymous',
  templateUrl: './report-details-anonymous.component.html',
  styleUrls: ['./report-details-anonymous.component.scss']
})
export class ReportDetailsAnonymousComponent implements OnInit {
  public downloadingLite = false;
  public downloadCompleted = false;

  public pinForm: FormGroup;
  public get pin() { return this.pinForm.get('pin'); }

  public reportId: number;

  public constructor(private readonly route: ActivatedRoute, private readonly reportsService: ReportsService, 
    private readonly fb: FormBuilder, private readonly toast: ToastService) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.reportId = params.id;
      };
    });

    this.pinForm = this.fb.group({
      pin: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  public loadReportLite(): void {
    this.downloadingLite = true;
    this.downloadCompleted = false;

    this.reportsService.getDetailsLite(this.reportId, this.pinForm.value.pin).subscribe(
      data => {
        if (!data) {
          this.toast.error("Invalid pin");
        }
        else {
          this.downloadCompleted = true;
        }
      },
      err => {
        console.log('err:', err)
      },
      () => this.downloadingLite = false
    );
  }
}
