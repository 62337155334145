import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-status-badge',
  styleUrls: ['./report-status-badge.component.scss'],
  templateUrl: './report-status-badge.component.html'
})
export class ReportStatusBadgeComponent {
  @Input()
  status: number;

  statusString() {
    switch (this.status) {
      case 0: return 'Pending';
      case 1: return 'Finished';
      case 2: return 'Error';
      case 4: return 'Queued';
      case 5: return 'Incomplete';
    }
  }

  statusClass() {
    switch (this.status) {
      case 0: return 'badge-pending';
      case 1: return 'badge-finished';
      case 2: return 'badge-error';
      case 4: return 'badge-queued';
      case 5: return 'badge-incomplete';
    }
  }
}
