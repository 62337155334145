import { Component } from '@angular/core';

@Component({
  selector: 'app-reports-landing-anonymous',
  templateUrl: './reports-landing-anonymous.component.html',
  styleUrls: ['./reports-landing-anonymous.component.scss']
})
export class ReportsLandingAnonymousComponent {

}
