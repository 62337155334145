import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { SignalRService } from './services/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showNavbar = false;

  public constructor(private router: Router, private activatedRoute: ActivatedRoute, private readonly signalRService: SignalRService) { }

  public ngOnInit(): void {
    this.signalRService.startConnection();
    this.signalRService.addReportStatusListener();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showNavbar = this.activatedRoute.firstChild.snapshot.data.showNavbar !== false;
      }
    });
  }
}
