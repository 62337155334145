import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { InitScanRequest } from '../../models/init-scan-request';
import { ReportsService } from 'src/app/services/reports.service';
import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { FadeOutAnim } from 'src/app/animations/fadeOutAnim';
import { ToastService } from 'src/app/services/toast.service';
import { ReportListItem } from './../../models/report-list-item';

@Component({
  selector: 'app-init-scan',
  templateUrl: './init-scan.component.html',
  styleUrls: ['./init-scan.component.scss'],
  animations: [FadeInAnim, FadeOutAnim]
})
export class InitScanComponent implements OnInit {
  public form: FormGroup;
  public get url() { return this.form.get('url'); }

  @ViewChild('inputUrl', null) inputEl: ElementRef;
  public initInProgress = false;

  public constructor(
    private readonly fb: FormBuilder,
    private readonly reportsService: ReportsService,
    private readonly toast: ToastService
  ) {
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      url: new FormControl('', [Validators.required, Validators.pattern('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')]),
    });
  }

  public submitUrl(type: number): void {
    this.initInProgress = true;

    const request: InitScanRequest = {
      notificationEmail: null,
      url: this.form.value.url,
      type
    };

    this.reportsService.initReport(request).subscribe(
      (res: ReportListItem) => {
        this.toast.success('URL has been sucesfully submitted.');
        this.reportsService.pushNewToList(res);
        this.initInProgress = false;
        // reset validation of input field and clean it.
        this.form.reset();
      },
      (err: any) => {
        this.toast.error(err.error);
        this.inputEl.nativeElement.focus();
        this.initInProgress = false;
      }
    );
  }
}






