import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-report-details-panel',
  templateUrl: './report-details-panel.component.html',
  styleUrls: ['./report-details-panel.component.scss']
})
export class ReportDetailsPanelComponent implements OnInit {

  public reportId: number;

  public constructor(private readonly route: ActivatedRoute, private readonly reportsService: ReportsService) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.reportId = params.id;
        this.reportsService.getDetails(this.reportId);
      };
    });
  }
}
