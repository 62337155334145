import {
  trigger,
  animate,
  transition,
  style,
  query
} from '@angular/animations';

export const SlideRightAnim = trigger('slideRightAnim', [
  transition('* => *', [
    query(
      ':leave',
      [style({ opacity: 1, right: '0' }), animate('0.3s', style({ opacity: 0, right: '-100%' }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0, right: '-100%' }), animate('0.3s', style({ opacity: 1, right: '0' }))],
      { optional: true }
    )
  ])
]);
