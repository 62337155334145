import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';

import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { User } from '../../models/user';
import { AdminUserListService } from '../../services/admin.user-list.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss'],
  animations: [FadeInAnim]
})
export class AdminUSerListComponent implements OnInit, OnDestroy {
  private usersEnablingSub: Subscription;
  private usersDisablingSub: Subscription;

  private editUserModal: NzModalRef;

  public users$: Observable<User[]>;
  public usersLoading$: Observable<boolean>;

  public constructor(
    private adminUserListService: AdminUserListService,
    private modalService: NzModalService,
    private toast: ToastService) {
  }

  public ngOnInit(): void {
    this.users$ = this.adminUserListService.users;
    this.usersLoading$ = this.adminUserListService.usersLoading;

    this.usersEnablingSub = this.adminUserListService.usersEnabling.subscribe((id: number) => {
      this.toast.success("User account succesfully enabled.");
    });

    this.usersDisablingSub = this.adminUserListService.usersDisabling.subscribe((id: number) => {
      this.toast.success("User account succesfully disabled.");
    });
  }

  public ngOnDestroy(): void {
    this.usersEnablingSub.unsubscribe();
    this.usersDisablingSub.unsubscribe();
  }

  public createEditUserModal(id: number, tplContent: TemplateRef<{}>): void {
    this.editUserModal = this.modalService.create({
      nzTitle: 'Edit User',
      nzFooter: null,
      nzContent: tplContent,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: '600'
    });
  }

  public onDoneEditingUser(): void {
    this.editUserModal.close();
  }

  public enableUser(id: number): void {
    this.adminUserListService.enableUser(id);
  }

  public disableUser(id: number): void {
    this.adminUserListService.disableUser(id);
  }
}
