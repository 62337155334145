import { AdminRootComponent } from './component/admin-root/admin-root.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';

const routes: Routes = [
  { path: 'admin', component: AdminRootComponent, canActivate: [AuthGuard], data: { animation: 'reports' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
