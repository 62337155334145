import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { InitScanRequest } from '../../models/init-scan-request';
import { ReportsService } from 'src/app/services/reports.service';
import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { FadeOutAnim } from 'src/app/animations/fadeOutAnim';
import { ToastService } from 'src/app/services/toast.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-init-scan-anonymous',
  templateUrl: './init-scan-anonymous.component.html',
  styleUrls: ['./init-scan-anonymous.component.scss'],
  animations: [FadeInAnim, FadeOutAnim]
})
export class InitScanAnonymousComponent implements OnInit {
  private token: string;
  public validated: boolean = false;

  public form: FormGroup;
  public get email() { return this.form.get('email'); }
  public get name() { return this.form.get('name'); }
  public get phone() { return this.form.get('phone'); }
  public get url() { return this.form.get('url'); }

  @ViewChild('inputUrl', null) inputEl: ElementRef;
  public initInProgress = false;

  public constructor(
    private readonly fb: FormBuilder,
    private readonly reportsService: ReportsService,
    private readonly toast: ToastService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params.token;

      this.reportsService.validateInitReportToken(params.token).subscribe((valid: boolean) => {
        if (!valid) {
          this.router.navigate(['not-found']);
        }
        else {
          this.validated = true;
        }
      });
    });

    this.form = this.fb.group({
      email: ['', [Validators.required]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      url: new FormControl('', [Validators.required, Validators.pattern('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')]),
    });
  }

  public submitUrl(type: number): void {
    this.initInProgress = true;

    const request: InitScanRequest = {
      notificationEmail: this.form.value.email,
      url: this.form.value.url,
      type,
      unregisteredUserData: {
        token: this.token,
        email: this.form.value.email,
        name: this.form.value.name,
        phone: this.form.value.phone
      }
    };

    this.reportsService.initReport(request).subscribe(
      () => {
        this.initInProgress = false;
        this.router.navigate(['report-created']);
      },
      (err: any) => {
        console.error(err);
        this.toast.error(
          err.error
        );
        this.inputEl.nativeElement.focus();
        this.initInProgress = false;
      }
    );
  }
}
