import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './component/login/login.component';
import { SetPasswordComponent } from './component/set-password/set-password.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { SharedServicesModule } from 'src/app/services/shared.services.module';

@NgModule({
  imports: [
    SharedServicesModule,

    CommonModule,
    ComponentsModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [LoginComponent, SetPasswordComponent]
})
export class LoginModule {}
