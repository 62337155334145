import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  public export(filename: string, type: string, exportResult: any): void {
    const link = document.createElement('a');
    const blob = this.createNewBlob(exportResult, {
        type,
    });

    // For chrome
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();

    document.body.appendChild(link);
  }

  private createNewBlob(data: any, datatype: any): Blob {
      return new Blob([data], { type: datatype });
  }
}