import { animate, transition, style, trigger } from '@angular/animations';

export const SlideDownAnim = trigger('slideDownAnim', [
  transition(':enter', [
    style({ height: 0 }),
    animate('200ms ease-out', style({ height: '*' })),
  ]),
  transition(':leave', [
     animate('200ms ease-out', style({ height: 0 })),
  ])
]);
