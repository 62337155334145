import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './404/404.component';
import { Error403Component } from './403/403.component';

const routes: Routes = [
  { path: 'not-found', component: Error404Component, data: { showNavbar: false } },
  { path: 'no-access', component: Error403Component, data: { showNavbar: false } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsRoutingModule { }
