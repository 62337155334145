import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-security-score-icon',
  styleUrls: ['./report-security-score-icon.component.scss'],
  templateUrl: './report-security-score-icon.component.html'
})
export class ReportSecurityScoreIconComponent {
  @Input()
  score: number;

 scoreClassFore() {
    switch (this.score) {
      case 0: return 'severity-low-color';
      case 1: return 'severity-medium-color';
      case 2: return 'severity-high-color';
      default: return 'severity-info-color';
    }
  }
}
