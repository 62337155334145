import { NgModule, ModuleWithProviders  } from '@angular/core';
import { UsersService } from './users.service';
import { ReportsService } from './reports.service';
import { ExportService } from './export.service';

@NgModule({
})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedServicesModule,
      providers: [ UsersService, ReportsService, ExportService ]
    };
  }
}
