import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ReportStatistic } from 'src/app/modules/reports/models/report-statistic';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-report-statistic-chart',
  templateUrl: './report-statistic-chart.component.html',
  styleUrls: ['./report-statistic-chart.component.scss']
})
export class ReportStatisticChartComponent implements AfterViewInit, OnDestroy, OnChanges {

  private barWidthInPixel = 52;
  private chart: am4charts.XYChart;

  @Input()
  public data: ReportStatistic[];

  public constructor(private readonly zone: NgZone) { }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      let chart = am4core.create("report_statistics_chart", am4charts.XYChart);

      this.createAxis(chart);
      this.createSeries(chart);
      this.createTimeSeries(chart);
      
      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      this.addData(chart);

      chart.legend = new am4charts.Legend();

      this.chart = chart;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].isFirstChange()) { return; }
    
    this.chart.data = changes['data'].currentValue;
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private createAxis(chart: am4charts.XYChart): void {
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.title.text = "Reports";

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Total findings";
  }

  private createSeries(chart: am4charts.XYChart): void {
    const seriesLow = chart.series.push(new am4charts.ColumnSeries());
    seriesLow.dataFields.valueY = "low";
    seriesLow.dataFields.categoryX = "label";
    seriesLow.name = "Low Findings";
    seriesLow.tooltipText = "{name}: [bold]{valueY}[/]";
    seriesLow.stacked = true;
    seriesLow.columns.template.fill = am4core.color("#E9D758");
    seriesLow.columns.template.width = this.barWidthInPixel;

    const seriesMid = chart.series.push(new am4charts.ColumnSeries());
    seriesMid.dataFields.valueY = "medium";
    seriesMid.dataFields.categoryX = "label";
    seriesMid.name = "Medium Findings";
    seriesMid.tooltipText = "{name}: [bold]{valueY}[/]";
    seriesMid.stacked = true;
    seriesMid.columns.template.fill = am4core.color("#FF892A");
    seriesMid.columns.template.width = this.barWidthInPixel;

    const seriesHigh = chart.series.push(new am4charts.ColumnSeries());
    seriesHigh.dataFields.valueY = "high";
    seriesHigh.dataFields.categoryX = "label";
    seriesHigh.name = "High Findings";
    seriesHigh.tooltipText = "{name}: [bold]{valueY}[/]";
    seriesHigh.stacked = true;
    seriesHigh.columns.template.fill = am4core.color("#DD5A43");
    seriesHigh.columns.template.width = this.barWidthInPixel;
  }

  private createTimeSeries(chart: am4charts.XYChart): void {
    const trend = chart.series.push(new am4charts.LineSeries());
    trend.dataFields.valueY = "cvssTotal";
    trend.dataFields.categoryX = "label";
    trend.strokeWidth = 2;
    trend.stroke = am4core.color("#c00");
    trend.legendSettings.labelText = "CVSS Score";
    trend.tooltipText = "CVSS Score: {cvssTotal}"

    const bullet = trend.bullets.push(new am4charts.CircleBullet());
    bullet.strokeWidth = 2;
    bullet.stroke = am4core.color("#fff")
    bullet.circle.fill = trend.stroke;
    
    const bulletLabel = trend.bullets.push(new am4charts.LabelBullet())
    bulletLabel.label.text = "{cvssTotal}";
    bulletLabel.dy = -20;
  }

  private addData(chart: am4charts.XYChart): void {
    chart.data = this.data;
  }
}
