import { AuthGuard } from './../../guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsLandingComponent } from './component/reports-landing/reports-landing.component';
import { ReportsLandingAnonymousComponent } from './component/reports-landing-anonymous/reports-landing-anonymous.component';
import { ReportDetailsPanelComponent } from './component/report-details-panel/report-details-panel.component';
import { ReportDetailsAnonymousComponent } from './component/report-details-anonymous/report-details-anonymous.component';
import { ReportCreatedComponent } from './component/report-created/report-created.component';
import { CreateAccessCodeComponent } from './component/create-access-code/create-access-code.component';

const routes: Routes = [
  {
    path: 'reports', component: ReportsLandingComponent, canActivate: [AuthGuard], data: { animation: 'reports' }, children: [
      { path: ':id', component: ReportDetailsPanelComponent, outlet: 'reportDetails' }
    ],
  },
  { path: 'create-code', component: CreateAccessCodeComponent, canActivate: [AuthGuard], data: { animation: 'reports' } },
  { path: 'reports-free/:token', component: ReportsLandingAnonymousComponent, data: { showNavbar: false } },
  { path: 'report-created', component: ReportCreatedComponent },
  { path: 'report/:id', component: ReportDetailsAnonymousComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
