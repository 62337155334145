import { UpdateUserRequest } from './../../models/update-user-request';
import { UserRole } from '../../models/user-role';
import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';

import { AdminRolesService } from '../../services/admin.roles.service';
import { AdminEditUserService } from '../../services/admin.edit-user.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { SlideDownAnim } from 'src/app/animations/slideDownAnim';
import { ToastService } from 'src/app/services/toast.service';
import { OnUserUpdatedEvent } from '../../models/on-user-updated-event';
import { UserDetails } from '../../models/user-details';
import { AdminUserListService } from '../../services/admin.user-list.service';

@Component({
  selector: 'admin-edit-user',
  templateUrl: './admin-edit-user.component.html',
  styleUrls: ['./admin-edit-user.component.scss'],
  animations: [SlideDownAnim]
})
export class AdminEditUserComponent implements OnInit {
  @Input()
  userId: number;

  @Output() onDone: EventEmitter<any> = new EventEmitter();

  get email() { return this.form.get('email'); }
  get name() { return this.form.get('name'); }
  get surname() { return this.form.get('surname'); }
  get roles() { return this.form.get('roles') as FormArray; };

  userUpdatedSub: Subscription;
  form: FormGroup;
  userUpdatingData: OnUserUpdatedEvent;
  user$: Observable<UserDetails>;
  userRoles$: Observable<UserRole[]>;
  userRoles: UserRole[];
  userCreated$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private adminRolesService: AdminRolesService,
    private adminUpdateUserService: AdminEditUserService,
    private adminUserListService: AdminUserListService,
    private toast: ToastService) {

    this.form = this.fb.group({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      roles: new FormArray([])
    });
  }

  ngOnInit(): void {
    this.user$ = this.adminUpdateUserService.user;
    this.adminUpdateUserService.user.subscribe((e: UserDetails) => {
      if (!e) return;
      this.form.patchValue(e);
    });

    this.userRoles$ = this.adminRolesService.roles;
    this.adminRolesService.roles.subscribe((newRoles: UserRole[]) => {
      this.userRoles = newRoles;
      newRoles.map(r => {
        this.roles.push(this.fb.control(false));
      });
    });

    this.userUpdatedSub = this.adminUpdateUserService.userUpdatingData
      .subscribe((e: OnUserUpdatedEvent) => {
        this.userUpdatingData = e;

        if (e && e.userUpdated) {
          this.userUpdatedSub.unsubscribe();
          this.toast.success(
            'User has been sucesfully updated.'
          );
          this.adminUserListService.getUsers();
          this.onDone.emit();
          this.reset();
        }
      });

    this.adminUpdateUserService.getUserDetails(this.userId);
  }

  cancel(e: MouseEvent) {
    e.preventDefault();
    this.userUpdatedSub.unsubscribe();
    this.onDone.emit();
    this.reset();
  }

  reset() {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }

  update(values: UpdateUserRequest) {
    const selectedRoleIds = this.form.value.roles
      .map((v: any, i: string | number) => v ? this.userRoles[i].id : null)
      .filter((v: any) => v !== null);

    var updateUserRequest: UpdateUserRequest = values;
    updateUserRequest.roles = selectedRoleIds;
    updateUserRequest.id = this.userId;

    this.adminUpdateUserService.updateUser(updateUserRequest);
  }
}
