import { UserInfo } from '../../modules/login/models/user-info';
import { Component, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnDestroy {
  private userInfoSubscription: Subscription;

  public userInfo: UserInfo;

  public showNavs: boolean = localStorage.getItem("token") !== null;
  public isAdmin: boolean = false;
  public isNotClient: boolean = false;

  public constructor(private usersService: UsersService) {
    this.userInfoSubscription = usersService.userInfo.subscribe(userInfo => {
      this.isAdmin = userInfo && userInfo.isAdmin;
      this.isNotClient = userInfo && !userInfo.isClient;
      this.userInfo = userInfo;
    });
  }

  public ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
  }

  public logout(): void {
    this.usersService.logout();
  }
}
