import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUserListService {
  constructor(private httpClient: HttpClient) { }

  private apiUrl = environment.apiUrl;
  private usersSubject = new Subject<User[]>();
  private gettingUsersSubject = new Subject<boolean>();
  private enablingUsersSubject = new Subject<number>();
  private disablingUsersSubject = new Subject<number>();

  readonly users: Observable<User[]> = this.usersSubject.asObservable();
  readonly usersLoading: Observable<boolean> = this.gettingUsersSubject.asObservable();
  readonly usersEnabling: Observable<number> = this.enablingUsersSubject.asObservable();
  readonly usersDisabling: Observable<number> = this.disablingUsersSubject.asObservable();

  private dataStore: {
    users: User[]
  } = {
    users: []
  };

  public getUsers() {
    this.gettingUsersSubject.next(true);

    this.httpClient.get<User[]>(`${this.apiUrl}/users`).subscribe(data => {
      this.dataStore.users = data;
      this.gettingUsersSubject.next(false);

      this.usersSubject.next(data);
    });
  }

  public enableUser(id: number) {
    this.httpClient.put(`${this.apiUrl}/users/${id}/enable`, '').subscribe(data => {
      var updatedUser = this.dataStore.users.filter(function(u) {
        return u.id === id;
      })[0];

      if (updatedUser) {
        updatedUser.isEnabled = true
      }

      const newList = Object.assign({}, this.dataStore).users;
      this.usersSubject.next([...newList]);
      this.enablingUsersSubject.next(id)
    });
  }

  public disableUser(id: number) {
    this.httpClient.put(`${this.apiUrl}/users/${id}/disable`, '').subscribe(data => {
      var updatedUser = this.dataStore.users.filter(function(u) {
        return u.id === id;
      })[0];

      if (updatedUser) {
        updatedUser.isEnabled = false
      }

      const newList = Object.assign({}, this.dataStore).users;
      this.usersSubject.next([...newList]);
      this.disablingUsersSubject.next(id)
    });
  }
}
