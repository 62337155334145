import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { ChartType } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';

import { ReportDetails } from 'src/app/modules/reports/models/report-details';
import { ReportsService } from 'src/app/services/reports.service';
import { ExportService } from 'src/app/services/export.service';
import { ReportStatistic } from 'src/app/modules/reports/models/report-statistic';

@Component({
  selector: 'app-report-details',
  styleUrls: ['./report-details.component.scss'],
  templateUrl: './report-details.component.html'
})
export class ReportDetailsComponent implements OnInit {
  private reportSub: Subscription;

  private downloadFileModal: NzModalRef;
  
  @Input()
  public mode: string;

  @Input()
  public reportId: number;

  @Input()
  public showChart: boolean;

  public report$: Observable<ReportDetails>;
  public reportStatistics$: Observable<ReportStatistic[]>;
  
  // Doughnut
  public doughnutChartLabels: Label[] = ['High', 'Medium', 'Low', 'Information'];
  public doughnutChartData: SingleDataSet = [];
  public doughnutChartType: ChartType = 'doughnut';
  public chartColors: any[] = [
    {
      backgroundColor: ['#d0021b', '#FF892A', '#E9D758', '#478FCA']
    }];

  public constructor(private readonly reportsService: ReportsService,  
      private readonly modalService: NzModalService,
      private readonly exportService: ExportService) {
  }

  public ngOnInit(): void {
    this.report$ = this.reportsService.currentReportSubject.asObservable();
    this.reportSub = this.report$.subscribe(r => {
      if (!r) { return; }

      if (this.showChart) {
        this.reportStatistics$ = this.reportsService.getReportStatisticsById(r.id);
      }
      
      this.doughnutChartData = [r.high, r.medium, r.low, r.info];
    });
  }
  
  public ngOnDestroy(): void {
    this.reportSub.unsubscribe();
  }

  public downloadPdf(id: number): void {
    if (this.mode === 'Lite') {
      this.downloadPdfLite(id);
    } else if (this.mode === 'Full') {
      this.downloadPdfFull(id);
    }
  }

  public calculateAccumulatedCvssScore(cvssTotal: number): string {
    if (!cvssTotal) { 
      return 'N/A';
    }

    return cvssTotal.toFixed(1);
  }

  private downloadPdfFull(id: number): void {
    this.downloadPdfGeneric(() => this.reportsService.downloadPdfFile(id), id);
  }

  private downloadPdfLite(id: number): void {
    this.downloadPdfGeneric(() => this.reportsService.downloadPdfLiteFile(id), id);
  }

  private downloadPdfGeneric(serviceCall: () => Observable<HttpEvent<Blob>>, id: number): void {
    // Create new window during user click event
    const fileTab = window.open('', '_blank');

    serviceCall().subscribe(result => {
      if (result.type === HttpEventType.Response) {
        const downloadedFile = new Blob([result.body], { type: result.body.type });
        const fileURL = URL.createObjectURL(downloadedFile);

        // Replace url to display actual file
        fileTab.location.href = fileURL;
        
        // Check if tab was properly open, if not display popup with download option
        setTimeout(() => {
          // Don't break CORS, but check what is the outerHeight. If 0, then the tab was not open
          const { outerHeight } = fileTab;
          if (outerHeight === 0) {
            this.createDownloadModal(`${id}.pdf`, result.body.type, result.body);
          }
        }, 250);
      }
    });
  }

  private createDownloadModal(filename: string, type: string, result: any): void {
    this.downloadFileModal = this.modalService.confirm({
      nzTitle: `Popups are disabled`,
      nzContent: 'We cannot display the pdf file. Please enable popups in your browser or download file.',
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: '400',
      nzOkText: 'Download',
      nzZIndex: 1005,
      nzOnOk: () => {
        this.exportService.export(filename, type, result);
        this.downloadFileModal.close();
      }
    })
  }
}
