import { Component, OnInit } from '@angular/core';
import { SlideDownAnim } from 'src/app/animations/slideDownAnim';
import { AdminRolesService } from '../../services/admin.roles.service';
import { AdminUserListService } from '../../services/admin.user-list.service';

@Component({
  selector: 'admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss'],
  animations: [SlideDownAnim]
})
export class AdminRootComponent implements OnInit {
  isAddingUser: boolean = false;

  constructor(private adminRolesService: AdminRolesService, private adminUsersService: AdminUserListService) {}

  ngOnInit() {
    this.adminUsersService.getUsers();
    this.adminRolesService.getRoles();
  }

  showNewUserPanel() {
    this.isAddingUser = true;
  }

  hideNewUserPanel() {
    this.isAddingUser = false;
  }

  prepRouteState(outlet: any) {
    return outlet.activatedRouteData.animation || 'reports';
  }
}
