import { Injectable } from '@angular/core';
import { CreateUserRequest } from '../models/create-user-request';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { OnUserCreatedEvent } from '../models/on-user-created-event';

@Injectable({
  providedIn: 'root'
})
export class AdminCreateUserService {
  constructor(private httpClient: HttpClient) { }
  private apiUrl = environment.apiUrl;
  private userCreatingSubject = new Subject<OnUserCreatedEvent>();

  readonly userCreatingData: Observable<OnUserCreatedEvent> = this.userCreatingSubject.asObservable();

  public createUser(user: CreateUserRequest) {
    this.userCreatingSubject.next({
      inProgress: true,
      userCreated: false,
      errors: []
    });

    this.httpClient.post(`${this.apiUrl}/account/register`, user).subscribe(
      () => {
        //all ok
        this.userCreatingSubject.next({
          inProgress: false,
          userCreated: true,
          errors: []
        });
      },
      (err: any) => {
        //error
        this.userCreatingSubject.next({
          inProgress: false,
          userCreated: false,
          errors: [err.error]
        });
      });
  }

  public checkIfEmailTaken(email: string) {
    return this.httpClient.get<boolean>(`${this.apiUrl}/users/emailTaken?email=${email}`);
  }
}
