import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";

import { environment } from 'src/environments/environment';
import { ReportsService } from './reports.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  private hubConnection: signalR.HubConnection

  public constructor(private readonly reportService: ReportsService) {
  }

  public startConnection(): void {
    const url = `${environment.backendUrl}reportNotification`;
    console.log('url:', url);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        console.log('Error: ', err);
      });
  }

  public addReportStatusListener(): void {
    this.hubConnection.on('updateReportStatus', (notification: ReportNotificationModel) => {
      this.reportService.refreshStatus(notification.reportId.toString());
    });
  }
}

interface ReportNotificationModel {
  reportId: number;
}
