import { ToastService } from '../../../../services/toast.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlideDownAnim } from 'src/app/animations/slideDownAnim';
import { AdminReportsService } from '../../../admin/services/admin.reports.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'create-access-code',
  templateUrl: './create-access-code.component.html',
  styleUrls: ['./create-access-code.component.scss'],
  animations: [SlideDownAnim]
})
export class CreateAccessCodeComponent implements OnInit, OnDestroy {
  token$: Observable<string>;
  url: string;

  private tokenSubscription: Subscription;

  public constructor(private adminReportsService: AdminReportsService, private toast: ToastService) {
    this.token$ = adminReportsService.token;
  }

  public ngOnInit(): void {
    this.tokenSubscription = this.token$.subscribe(token => {
      var getUrl = window.location;
      this.url = getUrl.protocol + "//" + getUrl.host + "/reports-free/" + token;
    })
  }

  public ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }

  public generateCode() {
    this.adminReportsService.getInitReportAccessToken();
  }

  public copied() {
    this.toast.success("Link copied")
  }
}
