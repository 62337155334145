import { Component, OnInit, TemplateRef, OnDestroy, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { format } from 'date-fns';

import { ToastService } from 'src/app/services/toast.service';
import { ReportsService } from 'src/app/services/reports.service';
import { FadeInAnim } from 'src/app/animations/fadeInAnim';
import { FadeOutAnim } from 'src/app/animations/fadeOutAnim';
import { SlideDownAnim } from 'src/app/animations/slideDownAnim';
import { UsersService } from 'src/app/services/users.service';
import { ExportService } from 'src/app/services/export.service';

import { ReportListItem } from '../../models/report-list-item';
import { ReportImportExportService } from '../../services/report-import-export.service';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
  animations: [FadeInAnim, FadeOutAnim, SlideDownAnim]
})
export class ReportsListComponent implements OnInit, OnDestroy {
  private assigningUserToReport: boolean = false;
  private assigningUserToReportSub: Subscription;

  private sortName: string | null = null;
  private sortValue: string | null = null;

  private tplAssignUsersModal: NzModalRef;
  private usersToAssign: any;

  public reports$: Observable<ReportListItem[]>;
  public reportsLite$: Observable<ReportListItem[]>;
  public gettingReports$: Observable<boolean>;
  public gettingReportsLite$: Observable<boolean>;  

  // Import & Export properties
  public isExporting: boolean;

  public exportingReports$: Observable<boolean>;
  public importingReports$: Observable<boolean>;
  public exportStartDate: Date;
  public exportEndDate: Date;

  @ViewChild('importFile', { static: false })
  private importFile: ElementRef;

  // TODO: Explain purpose of property
  public currentlyDownloading = {};

  public isAdmin: boolean = this.usersService.dataStore.userInfo.isAdmin;
  public isPlanner: boolean = this.usersService.dataStore.userInfo.isPlanner;

  public constructor(
    private readonly modalService: NzModalService,
    private readonly reportsService: ReportsService,
    private readonly usersService: UsersService,
    private readonly toast: ToastService,
    private readonly exportService: ExportService,
    private readonly reportImportExportService: ReportImportExportService) {
  }

  public ngOnInit(): void {
    this.reports$ = this.reportsService.reportsSubject;
    this.reportsLite$ = this.reportsService.reportsLiteSubject;

    this.gettingReports$ = this.reportsService.gettingReportsSubject.asObservable();
    this.gettingReportsLite$ = this.reportsService.gettingReportsLiteSubject.asObservable();
    this.assigningUserToReportSub = this.reportsService.assigningUserToReport.subscribe((assigning: boolean) => {
      this.assigningUserToReport = assigning;
    });

    this.loadRegisteredReports();

    if (this.isAdmin || this.isPlanner) {
      this.loadUnregisteredReports();
    }
  }

  public ngOnDestroy(): void {
    this.assigningUserToReportSub.unsubscribe();
  }

  public sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
  }

  public loadRegisteredReports(): void {
    this.reportsService.loadRegisteredReports();
  }

  public loadUnregisteredReports(): void {
    this.reportsService.loadUnregisteredReports();
  }

  public downloadPdf(event: Event, id: number): void {
    // Create new window during user click event
    const fileTab = window.open('', '_blank');
    event.preventDefault();
    this.currentlyDownloading[id] = 0;

    this.reportsService.downloadPdfFile(id).subscribe(result => {
      if (result.type === HttpEventType.DownloadProgress) {
        this.currentlyDownloading[id] = Math.round(100 * result.loaded / result.total);
      }
      if (result.type === HttpEventType.Response) {
        const downloadedFile = new Blob([result.body], { type: result.body.type });
        const fileURL = URL.createObjectURL(downloadedFile);

        delete this.currentlyDownloading[id];
        // Replace url to display actual file
        fileTab.location.href = fileURL;

        // Check if tab was properly open, if not display popup with download option
        setTimeout(() => {
          // Don't break CORS, but check what is the outerHeight. If 0, then the tab was not open
          const { outerHeight } = fileTab;
          if (outerHeight === 0) {
            this.exportService.export(`${id}.pdf`, result.body.type, result.body);
          }
        }, 250);
      }
    });
  }

  public createTplModal(tplContent: TemplateRef<{}>): void {
    this.tplAssignUsersModal = this.modalService.create({
      nzTitle: 'Assign Users',
      nzContent: tplContent,
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: '400',
      nzOkLoading: this.assigningUserToReport,
      nzOnOk: () => {
        if (this.usersToAssign && this.usersToAssign.userIds) {
          this.reportsService.assignUsersToReport(this.usersToAssign);
          this.toast.success('Users has been succesfully saved');
        }
      }
    });
  }

  public onAssignedUsersChange(data: any): void {
    this.usersToAssign = data;
  }

  public deleteReport(reportId: number): void {
    this.reportsService.deleteReport(reportId).subscribe(() => {
      this.toast.success('Report has been succesfully deleted');

    }, () => {
      this.toast.error('We have encountered an error during deletion of the report');
    })
  }
  // Export & Import Properties and Methods

  public get areExportDatesNotSelected(): boolean {
    return !(!!this.exportStartDate && !!this.exportEndDate);
  }

  public initExport(): void {
    this.isExporting = true;
  }

  public stopExport(): void {
    this.isExporting = false;
  }  

  public exportJson(): void {
    this.reportImportExportService.exportJSON(this.exportStartDate, this.exportEndDate).subscribe((result) => {
      if (result.type === HttpEventType.Response) {
        const name = `report_export_${format(new Date(), "YYYY-MM-DD_HHmmss")}.zip`;
        this.exportService.export(name, result.body.type, result.body);
        this.isExporting = false;
      }
    }, () => {
      this.isExporting = false;
    });
  }

  public exportXlsx(): void {
    this.reportImportExportService.export(this.exportStartDate, this.exportEndDate).subscribe((result) => {
      if (result.type === HttpEventType.Response) {
        const name = `report_export_${format(new Date(), "YYYY-MM-DD_HHmmss")}.xlsx`;
        this.exportService.export(name, result.body.type, result.body);
        this.isExporting = false;
      }
    }, () => {
      this.isExporting = false;
    });    
  }

  public onDateChange(dateRanges: Date[]): void {
    const [from, to] = dateRanges;
    this.exportStartDate = from;
    this.exportEndDate = to;
  }

  public handleFileInput(files: FileList): void {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('import', fileToUpload)
    this.reportImportExportService.import(formData).subscribe((scheduledReports) => {
      scheduledReports.forEach(scheduledReport => this.reportsService.pushNewToList(scheduledReport));
    });
  }

  public import(): void {
    this.importFile.nativeElement.click();
  }
}
