import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

import { UsersService } from 'src/app/services/users.service';
import { TokenData } from '../modules/login/models/token-data';

@Injectable()
export class SetHeadersInterceptor implements HttpInterceptor {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isRefreshingToken: boolean = false;

  public constructor(private authService: UsersService) { }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set("Authorization",
        "Bearer " + token)
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap((userInfo: TokenData) => {
            if (userInfo) {
              this.tokenSubject.next(userInfo.token);;
              localStorage.setItem('token', userInfo.token);
              localStorage.setItem('refreshToken', userInfo.refreshToken);
              return next.handle(this.addTokenToRequest(request, userInfo.token));
            }

            return <any>this.authService.logout();
          }),
          catchError(err => {
            return <any>this.authService.logout();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addTokenToRequest(request, token));
          })
        );
    }
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    return next.handle(this.addTokenToRequest(request, this.authService.getAuthToken()))
      .pipe(
        catchError(err => {
          if (!(err instanceof HttpErrorResponse)) {
            return throwError(err);
          }

          const status = (<HttpErrorResponse>err).status;

          switch (status) {
            case 0: {
              if (request.url.includes('login')) {
                return throwError(err);
              }
            }
            case 401:
              return this.handle401Error(request, next);
            case 400: {
              return throwError(err);
            }
          }
        }));
  }
}
