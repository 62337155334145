import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserRole } from '../models/user-role';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminRolesService {
  constructor(private httpClient: HttpClient) { }

  private apiUrl = environment.apiUrl;

  private rolesSubject = new BehaviorSubject<UserRole[]>([]);
  readonly roles: Observable<UserRole[]> = this.rolesSubject.asObservable();

  public getRoles() {
    this.httpClient.get<UserRole[]>(`${this.apiUrl}/userRoles`).subscribe(data => {
      this.rolesSubject.next(data);
    });
  }
}
